const styles = () => ({
  socialMediaText: {
    marginTop: '25px',
    fontSize: '32px',
    fontWeight: '600',
  },
  mediumSocialMediaText: {
    marginTop: '10px',
    fontSize: '20px',
    fontWeight: '600',
  },
  largeSocialMediaText: {
    marginTop: '10px',
    fontSize: '18px',
    fontWeight: '600',
  },
  marginLinebreak: {
    marginBottom: '20px',
  },
})

export default styles
