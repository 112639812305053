import React, { useState } from 'react'
import PropTypes from 'prop-types'

import PerfectScrollbar from 'react-perfect-scrollbar'

import clsx from 'clsx'

import { routes } from 'Routes'
import { reverse } from 'named-urls'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { Button, Chip } from '@material-ui/core'
import { MenuButton, Permitted } from 'components'
import ConfirmationDialog from '../ConfirmationDialog'

import { useHistory } from 'react-router-dom'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

import useStyles from './styles'

const CompanyFragilitiesTable = ({
  companyFragilities,
  isLoading,
  refresh,
}) => {
  const [destroyOpen, setDestroyOpen] = useState(false)
  const [companyFragility, setCompanyFragility] = useState()

  const classes = useStyles()

  const snackbar = useSnackbar()
  const history = useHistory()

  const handleEdit = (companyFragilityId) => {
    history.push(
      reverse(routes.companyFragilities.edit, { companyFragilityId }),
    )
  }

  const handleDestroy = async () => {
    try {
      await service.dponet.companyFragilities.destroy(companyFragility?.id)
      setCompanyFragility()
      setDestroyOpen(false)
      refresh()
      snackbar.open({
        variant: 'success',
        message: 'Ameaça excluída com sucesso',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
      })
    }
  }

  const handleConfirmDestroy = (companyFragility) => {
    setDestroyOpen(true)
    setCompanyFragility(companyFragility)
  }

  return (
    <PerfectScrollbar
      options={{ wheelPropagation: false, useBothWheelAxes: true }}
    >
      <Table
        size="small"
        emptyMessage="Nenhuma ameaça encontrada"
        isLoading={isLoading}
        noWrap
      >
        <TableHead>
          <TableRow>
            <TableCell width="10%">Identificador</TableCell>
            <TableCell width="25%">Ameaças</TableCell>
            <TableCell width="15%">Medidas vinculadas</TableCell>
            <TableCell width="20%">Local de ocorrência</TableCell>
            <TableCell width="10%" align="center">
              Nível
            </TableCell>
            <TableCell width="10%" align="center">
              Status
            </TableCell>
            <Permitted
              someTags={[
                constants.permissions.COMPANY_FRAGILITIES
                  .MANAGE_COMPANY_FRAGILITIES,
                constants.permissions.COMPANY_FRAGILITIES
                  .MANAGE_COMPANY_FRAGILITIES_BY_DEPARTMENT,
              ]}
            >
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </Permitted>
          </TableRow>
        </TableHead>
        <TableBody>
          {companyFragilities?.map((companyFragility) => (
            <TableRow key={companyFragility?.id}>
              <TableCell>{companyFragility?.id}</TableCell>
              <TableCell>{companyFragility?.name}</TableCell>
              <TableCell>
                {companyFragility?.vinculatedMeasures ? 'Sim' : 'Não'}
              </TableCell>
              <TableCell>
                {helpers.fragilities.fragilityEntitiesLabel(
                  companyFragility?.fragilityEntities,
                ) || '-'}
              </TableCell>
              <TableCell>
                <Chip
                  className={clsx(
                    classes.chip,
                    helpers.dataProcess.fragilityColor(
                      companyFragility?.fragilityId,
                      classes,
                    ),
                    helpers.dataProcess.textFragilityColor(
                      companyFragility?.fragilityId,
                      classes,
                    ),
                  )}
                  align="center"
                  size="small"
                  label={helpers.functions.upperText(
                    helpers.fragilities.idToLabel(
                      companyFragility?.fragilityId,
                    ),
                  )}
                />
              </TableCell>
              <TableCell>
                <Chip
                  className={clsx(
                    classes.chip,
                    classes[
                      constants.fragilities.STATUS_COLOR[
                        companyFragility?.status
                      ]
                    ],
                  )}
                  align="center"
                  size="small"
                  variant="outlined"
                  label={
                    constants.fragilities.STATUS_lABEL[companyFragility?.status]
                  }
                />
              </TableCell>
              <Permitted
                someTags={[
                  constants.permissions.COMPANY_FRAGILITIES
                    .MANAGE_COMPANY_FRAGILITIES,
                  constants.permissions.COMPANY_FRAGILITIES
                    .MANAGE_COMPANY_FRAGILITIES_BY_DEPARTMENT,
                ]}
              >
                <TableCell align="right">
                  <MenuButton
                    id={constants.fragilities.FRAGILITY_MAIN_DRIVER_STEP_3}
                  >
                    <Button
                      color="secondary"
                      fullWidth
                      size="small"
                      onClick={() => handleEdit(companyFragility?.id)}
                    >
                      Editar
                    </Button>
                    <Button
                      color="secondary"
                      fullWidth
                      size="small"
                      onClick={() => handleConfirmDestroy(companyFragility)}
                    >
                      Excluir
                    </Button>
                  </MenuButton>
                </TableCell>
              </Permitted>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog
        open={destroyOpen}
        setOpen={setDestroyOpen}
        title="Você tem certeza que deseja excluir esta ameaça?"
        actionButtonText="Excluir"
        handleSave={handleDestroy}
        description="Ao excluir uma ameaça, ele será desvinculado de todos os locais de ocorrência que você havia vinculado."
      />
    </PerfectScrollbar>
  )
}

CompanyFragilitiesTable.propTypes = {
  companyFragilities: PropTypes.array,
  isLoading: PropTypes.bool,
  refresh: PropTypes.func,
}

export default CompanyFragilitiesTable
