import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Container, Divider, Link, Typography } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import ForgotPasswordForm from './components/Form'

import { routes } from 'Routes'
import useStyles from './styles'

const ForgotPassword = ({
  whiteColor = false,
  email = '',
  enableRedirect = true,
  setClose = () => {},
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container maxWidth="xs" fullWidth>
        <Typography
          variant="h3"
          color="textPrimary"
          className={clsx(classes.colorBlue, {
            [classes.colorWhite]: whiteColor,
          })}
        >
          Olá!
        </Typography>
        <Typography
          variant="subtitle1"
          className={clsx(classes.colorBlue, {
            [classes.colorWhite]: whiteColor,
          })}
        >
          Digite seu e-mail para recuperar a senha
        </Typography>
        <Box mt={3}>
          <ForgotPasswordForm
            whiteColor={whiteColor}
            email={email}
            enableRedirect={enableRedirect}
            setClose={setClose}
          />
        </Box>
        {enableRedirect && (
          <>
            <Box my={2}>
              <Divider />
            </Box>
            <Link
              component={RouterLink}
              to={routes.root}
              variant="body2"
              color="textSecondary"
            >
              Voltar
            </Link>
          </>
        )}
      </Container>
    </Box>
  )
}

ForgotPassword.propTypes = {
  whiteColor: PropTypes.bool,
  email: PropTypes.string,
  enableRedirect: PropTypes.bool,
  setClose: PropTypes.func,
}

export default ForgotPassword
