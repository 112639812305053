import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { reverse } from 'named-urls'

import {
  BaseConfirmationDialog,
  LoadingFeedback,
  MenuButton,
  Permitted,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import permissions from 'constants/permissions'
import usePermissions from 'hooks/usePermissions'

const Actions = ({ supplierInvite = {}, refresh = () => {} }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openReactivateDialog, setOpenReactivateDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const snackbar = useSnackbar()
  const { permitted } = usePermissions()

  const supplierInviteConstants = constants.supplierInvite
  const companySupplier = supplierInvite?.companySupplier
  const supplierInviteStatus = supplierInvite?.status
  const hasCompanySuppliersUpdatePermission = permitted(
    permissions.COMPANY_SUPPLIERS.UPDATE,
  )

  const isSupplierInviteAccepted =
    supplierInviteStatus === supplierInviteConstants.STATUSES.ACCEPTED
  const isSupplierInviteRefused =
    supplierInviteStatus === supplierInviteConstants.STATUSES.REFUSED
  const isSupplierInvitePending =
    supplierInviteStatus === supplierInviteConstants.STATUSES.PENDING

  const handleEdit = () => {
    history.push(
      reverse(routes.thirdPartyManagements.partners.edit, {
        supplierInviteId: supplierInvite?.id,
      }),
    )
  }

  const handleVisualize = () => {
    history.push(
      reverse(routes.thirdPartyManagements.partners.show, {
        supplierInviteId: supplierInvite?.id,
      }),
    )
  }

  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true)

  const handleDelete = async () => {
    try {
      setLoading(true)
      if (isSupplierInviteAccepted) {
        await service.dponet.suppliers.softDelete({
          supplierId: companySupplier?.id,
        })
      } else {
        await service.dponet.supplierInvite.destroy({
          supplierInviteId: supplierInvite?.id,
        })
      }

      openSuccessSnackbar('Pré-avaliação excluída com sucesso')
      setOpenDeleteDialog(false)
      refresh()
    } catch (error) {
      openErrorSnackbar(error, 'Ocorreu um erro ao excluir')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenReactivateDialog = () => setOpenReactivateDialog(true)

  const handleReactivate = async () => {
    try {
      setLoading(true)
      await service.dponet.suppliers.resendSolicitation({
        supplierId: companySupplier?.id,
      })
      openSuccessSnackbar('Convite reenviado com sucesso')
      setOpenReactivateDialog(false)
      refresh()
    } catch (error) {
      openErrorSnackbar(error, 'Ocorreu um erro ao reativar')
    } finally {
      setLoading(false)
    }
  }

  const handleUpgradePreAvaliation = async () => {
    try {
      setLoading(true)
      await service.dponet.supplierInvite.upgradePreAvaliation({
        supplierInviteId: supplierInvite?.id,
      })

      openSuccessSnackbar('Pré-avaliação promovido para parceiro com sucesso')
      refresh()
    } catch (error) {
      openErrorSnackbar(error, 'Ocorreu um erro ao atualizar')
    } finally {
      setLoading(false)
    }
  }

  const openErrorSnackbar = (error, message) => {
    snackbar.open({
      message:
        helpers.formatters.errorMessage(error.response.data.error) || message,
      variant: 'error',
    })
  }

  const openSuccessSnackbar = (message) => {
    snackbar.open({
      message,
      variant: 'success',
    })
  }

  return (
    <MenuButton>
      <LoadingFeedback open={loading} />

      {isSupplierInviteAccepted && (
        <Button color="secondary" fullWidth onClick={handleVisualize}>
          Visualizar
        </Button>
      )}

      {!isSupplierInviteAccepted && !hasCompanySuppliersUpdatePermission && (
        <Button color="secondary" fullWidth disabled>
          NENHUMA AÇÃO DISPONÍVEL
        </Button>
      )}

      <Permitted tag={permissions.COMPANY_SUPPLIERS.UPDATE}>
        {(isSupplierInviteAccepted || isSupplierInvitePending) && (
          <Button
            color="secondary"
            fullWidth
            onClick={handleEdit}
            disabled={isEmpty(supplierInvite)}
          >
            Editar
          </Button>
        )}

        <Button color="secondary" fullWidth onClick={handleOpenDeleteDialog}>
          Excluir
        </Button>

        {(isSupplierInviteRefused || isSupplierInvitePending) && (
          <Button
            color="secondary"
            fullWidth
            onClick={handleOpenReactivateDialog}
          >
            Reenviar convite
          </Button>
        )}

        <Button
          color="secondary"
          fullWidth
          onClick={handleUpgradePreAvaliation}
        >
          Promover a parceiro
        </Button>
      </Permitted>

      {openDeleteDialog && (
        <BaseConfirmationDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          title="Excluir vínculo?"
          text="Deseja confirmar a exclusão do parceiro?"
          actionButtonText="Confirmar"
          variant="warning"
          actionButton={handleDelete}
          loading={loading}
        />
      )}

      {openReactivateDialog && (
        <BaseConfirmationDialog
          open={openReactivateDialog}
          setOpen={setOpenReactivateDialog}
          title="Reenviar convite"
          text='Ao reativar um fornecedor, um novo convite é enviado automaticamente. Você pode encontrá-lo na aba "Convites".'
          actionButtonText="Confirmar"
          variant="blueConfirmation"
          actionButton={handleReactivate}
          loading={loading}
        />
      )}
    </MenuButton>
  )
}

export default Actions
