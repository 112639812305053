import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  dialogAlert: {
    background: `linear-gradient(135deg, ${theme.palette.yellowWarningLight} 0%,${theme.palette.yellowWarningDark} 100%) !important`,
    padding: theme.spacing(2.5),
  },
  colorWhite: {
    color: theme.palette.white,
  },
  fontTitle: {
    color: theme.palette.white,
    fontWeight: 700,
  },
  button: {
    backgroundColor: theme.palette.white,
  },
}))

export default styles
