import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ReCAPTCHA from 'react-google-recaptcha'
import { Box, Button, Divider, InputLabel, Typography } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { getGoogleRecaptchaToken } from 'service/env'
import { routes } from 'Routes'

import {
  LoadingFeedback,
  PasswordInputField,
  PasswordValidator,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useSupplierInvite from 'hooks/useSupplierInvite'
import useAuth from 'hooks/useAuth'

import useStyles from './styles'
import * as service from 'service'
import schema from './schema'
import helpers from 'helpers'
import constants from 'constants/index'
import theme from 'theme'
import ForgotPassword from 'views/ForgotPassword'

const PasswordForm = ({ isNewUser, companyToken }) => {
  const [recaptcha, setRecaptcha] = useState(undefined)
  const [justSubmitted, setJustSubmitted] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)

  const recaptchaRef = useRef(null)
  const { supplierData, isLoading, setIsLoading } = useSupplierInvite()

  const production = process.env.REACT_APP_API === 'production'
  const disableSubmitButton = production ? !recaptcha || isLoading : false

  const snackbar = useSnackbar()
  const classes = useStyles()
  const history = useHistory()
  const { loadData } = useAuth()

  const isAcceptedLink =
    supplierData?.status === constants.supplierInvite.STATUSES.ACCEPTED
  const isCompleteLogin = !!supplierData?.supplierAlredyExists

  const { handleSubmit, errors, control, watch } = useForm({
    validationSchema: schema(isNewUser),
    defaultValues: {
      email: supplierData.email || '',
      password: '',
      passwordConfirmation: '',
    },
  })

  const handleCreateSupplierRelations = async (data) =>
    service.dponet.supplierInvite.createSupplierRelations(
      {
        supplierInvite: {
          companyName: supplierData.companyName,
          document: supplierData.document,
          email: data.email || supplierData.email,
          password: data.password,
          confirmPassword: data.confirmPassword || null,
        },
      },
      companyToken,
    )

  const handleLogin = async (data) =>
    await service.dponet.auth.login({
      email: data.email || supplierData.email,
      password: data.password,
      recaptcha,
    })

  const handleSelectCompany = async (userCompanyId) => {
    const response = await service.dponet.usersCompanies.selectCompany(
      userCompanyId,
    )

    const authToken = response?.data?.authToken
    const userCompany = response?.data?.userCompany

    if (!authToken) throw new Error('Erro ao acessar a empresa!')

    service.dponet.auth.setToken(authToken)
    service.dponet.auth.setCompany(userCompany?.companyId)
  }

  const companyAlreadyAcceptedAction = async (data) => {
    await handleLogin(data)
    const userCompanyId = supplierData?.userCompanyId

    await handleSelectCompany(userCompanyId)

    service.dponet.auth.removeSupplierInviteToken()

    await loadData()

    history.push(routes.root)
  }

  const responsibleUserAlreadyExistsAction = async (data) => {
    await handleLogin(data)

    const supplierRelationsResponse = await handleCreateSupplierRelations(data)
    const userCompany = supplierRelationsResponse?.data?.userCompany

    await handleSelectCompany(userCompany?.id || supplierData?.userCompanyId)

    service.dponet.auth.removeSupplierInviteToken()

    await loadData()

    history.push({
      pathname: reverse(routes.supplierStep),
      search: 'skipConfirmation=true',
    })
  }

  const createSupplierRelation = async (data) => {
    const supplierRelationsResponse = await handleCreateSupplierRelations(data)
    const userCompany = supplierRelationsResponse?.data?.userCompany

    await handleLogin(data)
    await handleSelectCompany(userCompany?.id)

    service.dponet.auth.removeSupplierInviteToken()

    await loadData()

    if (userCompany?.kind !== constants.company.SUPPLIER_KIND) {
      return history.push(routes.root)
    }

    return history.push({
      pathname: reverse(routes.supplierStep),
      search: 'skipConfirmation=true',
    })
  }

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      if (isAcceptedLink) {
        await companyAlreadyAcceptedAction(data)
      } else if (isCompleteLogin || !isNewUser) {
        await responsibleUserAlreadyExistsAction(data)
      } else {
        await createSupplierRelation(data)
      }
    } catch (error) {
      if (production) resetRecaptcha()

      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ||
          `Ocorreu um erro ao realizar o ${isNewUser ? 'cadastro' : 'login'}`,
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const resetRecaptcha = () => {
    recaptchaRef.current.reset()
    setRecaptcha(undefined)
  }

  const handleAcceptedRecaptcha = (recaptchaToken) => {
    setRecaptcha(recaptchaToken)
  }

  const disableRecaptcha = () => setRecaptcha(undefined)

  const handleSubmitForm = (event) => {
    setJustSubmitted(true)
    handleSubmit(onSubmit)(event)
  }

  const mountSubtitle = () => {
    if (isNewUser) {
      return 'A criação desta senha é fundamental para que você possa acessar a plataforma posteriormente.'
    }

    if (isAcceptedLink) {
      return 'Sua organização já acessou e aprovou previamente este vínculo. Faça login para acessar novamente.'
    }

    return 'Identificamos que seu usuário já existe na plataforma. Faça o login para continuar.'
  }

  const title = isNewUser ? 'Crie sua senha!' : 'Faça Login!'

  const handleForgotPassword = () => setForgotPassword(true)

  return (
    <Box>
      {forgotPassword ? (
        <ForgotPassword
          enableRedirect={false}
          whiteColor
          email={supplierData?.email}
          setClose={setForgotPassword}
        />
      ) : (
        <>
          <LoadingFeedback open={isLoading} />

          {!isLoading && (
            <Box>
              <Box className={classes.groupTypography}>
                <Typography className={clsx(classes.text, classes.textWelcome)}>
                  {title}
                </Typography>
                <Typography className={classes.text}>
                  {mountSubtitle()}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="center">
                <form onSubmit={handleSubmitForm} className={classes.form}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridGap={theme.spacing(2)}
                  >
                    <Box>
                      <InputLabel className={classes.label}>Senha</InputLabel>

                      <Controller
                        as={
                          <PasswordInputField
                            className={classes.input}
                            type="password"
                            variant="outlined"
                            fullWidth
                            error={isNewUser ? !!errors.password : ''}
                          />
                        }
                        control={control}
                        name="password"
                        mode="onBlur"
                      />
                    </Box>

                    {isNewUser && (
                      <>
                        <Box>
                          <InputLabel className={classes.label}>
                            Confirme sua senha
                          </InputLabel>

                          <Controller
                            as={
                              <PasswordInputField
                                className={classes.input}
                                type="password"
                                variant="outlined"
                                fullWidth
                                error={!!errors.passwordConfirmation}
                                helperText={
                                  errors?.passwordConfirmation?.message
                                }
                              />
                            }
                            control={control}
                            name="passwordConfirmation"
                            mode="onBlur"
                          />
                        </Box>
                        <Box display="flex" justifyContent="center">
                          <PasswordValidator
                            passwordInput={watch('password')}
                            justSubmitted={justSubmitted}
                            isSupplierStep
                          />
                        </Box>
                      </>
                    )}
                    {production && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          render="explicit"
                          sitekey={getGoogleRecaptchaToken()}
                          onChange={handleAcceptedRecaptcha}
                          onExpired={disableRecaptcha}
                        />
                      </Box>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.roundedButton}
                      disabled={disableSubmitButton}
                    >
                      {isLoading ? 'Carregando...' : 'Acessar'}
                    </Button>
                    {!isNewUser && (
                      <>
                        <Divider />
                        <Typography
                          variant="body2"
                          className={classes.textForgot}
                          onClick={handleForgotPassword}
                        >
                          Esqueceu sua senha?
                        </Typography>
                      </>
                    )}
                  </Box>
                </form>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

PasswordForm.propTypes = {
  isNewUser: PropTypes.bool,
  companyToken: PropTypes.string,
}

export default PasswordForm
