import React from 'react'
import { CircularProgress, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useCustomTheme from 'hooks/useCustomTheme'

import useStyles from './styles'

const ScreenLoading = ({ loaded, children }) => {
  const classes = useStyles()
  const { loading } = useCustomTheme()

  const finalLoaded = !loading && loaded

  return (
    <>
      <div
        className={clsx(classes.root, {
          [classes.rootLoaded]: finalLoaded,
        })}
      >
        <div
          className={clsx(classes.bg, {
            [classes.bgLoaded]: finalLoaded,
          })}
        />

        <figure
          className={clsx(classes.logo, {
            [classes.logoLoaded]: finalLoaded,
          })}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="secondary" />
          </Box>
        </figure>
      </div>

      {finalLoaded && children}
    </>
  )
}

ScreenLoading.propTypes = {
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.any,
}

export default ScreenLoading
