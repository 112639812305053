const { makeStyles } = require('@material-ui/core')

const styles = makeStyles(() => ({
  addUrlButton: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
}))

export default styles
