import React, { useState } from 'react'
import { isEmpty, xor } from 'lodash'
import CompanyFragilityContext from 'contexts/CompanyFragilityContext'

import * as service from 'service'
import helpers from 'helpers'

const CompanyFragilityProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [companyFragility, setCompanyFragility] = useState({})
  const [fragilityEntities, setFragilityEntities] = useState([])
  const [questionControls, setQuestionControls] = useState([])
  const [measuresSelected, setMeasuresSelected] = useState([])
  const [fragilitiesSelected, setFragilitiesSelected] = useState([])
  const [lastProcessesIds, setLastProcessesIds] = useState([])

  const loadCompanyFragility = async (companyFragilityId) => {
    setLoading(true)
    const response = await service.dponet.companyFragilities.get({
      companyFragilityId,
    })
    const companyFragility = response?.data?.companyFragility
    const questionControls = companyFragility?.companyFragilityMeasures
    const fragilityEntities = companyFragility?.fragilityEntities

    setCompanyFragility(companyFragility)
    setFragilityEntities(fragilityEntities)
    setQuestionControls(questionControls)
    setMeasuresSelected(
      helpers.questionControls.dataMultiOptions(questionControls),
    )
    setFragilitiesSelected(fragilityEntities)
    setLoading(false)
  }

  const loadProcessesOptions = async (departmentId) => {
    if (!isEmpty(xor(lastProcessesIds, departmentId))) {
      setLastProcessesIds(departmentId)
      const response = await service.dponet.dataProcesses.get({
        departmentId,
        perPage: 9999,
        minimal: true,
        isFragilityList: true,
      })

      return helpers.fragilityEntities.dataMultiOptions(
        response?.data?.dataProcesses,
      )
    }
  }

  return (
    <CompanyFragilityContext.Provider
      value={{
        companyFragility,
        fragilitiesSelected,
        fragilityEntities,
        loadCompanyFragility,
        loading,
        loadProcessesOptions,
        measuresSelected,
        questionControls,
        setFragilitiesSelected,
        setFragilityEntities,
        setQuestionControls,
        setMeasuresSelected,
      }}
    >
      {children}
    </CompanyFragilityContext.Provider>
  )
}

export default CompanyFragilityProvider
