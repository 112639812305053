import React from 'react'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import {
  CreditCard as CreditCardIcon,
  Globe as GlobeIcon,
  Mail as MailIcon,
  Users as UsersIcon,
} from 'react-feather'

import { InfoItem } from 'views/ThirdPartyManagements/components'

import useStyles from './styles'

const RequiredSection = ({ supplierInvite, showTitle = false }) => {
  const classes = useStyles()

  const supplier = supplierInvite?.supplier || {}

  const infos = [
    {
      label: 'Razão social',
      value: supplier?.name,
      Icon: UsersIcon,
    },
    {
      label: 'Documento',
      value: supplier?.document,
      Icon: CreditCardIcon,
    },
    {
      label: 'E-mail',
      value: supplier?.email,
      Icon: MailIcon,
    },
    {
      label: 'Parceiro internacional',
      value: supplier?.international ? 'Sim' : 'Não',
      Icon: GlobeIcon,
    },
  ]

  return (
    <Box>
      {showTitle && (
        <Typography variant="h4" gutterBottom>
          Dados obrigatórios
        </Typography>
      )}

      <Paper component={Box} p={3} className={classes.paper}>
        <Grid container spacing={3}>
          {infos.map((info, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <InfoItem {...info} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  )
}

export default RequiredSection
