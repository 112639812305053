import permissions from '../permissions'

const COMPANY_FRAGILITIES = [
  {
    name: 'Visualizar todas as ameaças',
    tag: permissions.COMPANY_FRAGILITIES.LIST_COMPANY_FRAGILITIES,
  },
  {
    name: 'Gerenciar todas as ameaças',
    tag: permissions.COMPANY_FRAGILITIES.MANAGE_COMPANY_FRAGILITIES,
    description:
      'Ao permitir, o usuário poderá criar, editar e excluir ameaças',
  },
  {
    name: 'Visualizar todas as ameaças do departamento',
    tag: permissions.COMPANY_FRAGILITIES.LIST_COMPANY_FRAGILITIES_BY_DEPARTMENT,
    description:
      'Ao permitir, o usuário poderá listar e visualizar as ameaças do departamento ao qual pertence',
  },
  {
    name: 'Gerenciar todas as ameaças do departamento',
    tag: permissions.COMPANY_FRAGILITIES
      .MANAGE_COMPANY_FRAGILITIES_BY_DEPARTMENT,
    description:
      'Ao permitir, o usuário poderá criar, editar e excluir ameaças do departamento ao qual pertence',
  },
]

export default COMPANY_FRAGILITIES
