import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Box, Button, TextField } from '@material-ui/core'
import ReCAPTCHA from 'react-google-recaptcha'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'

import { getGoogleRecaptchaToken } from 'service/env'
import { routes } from 'Routes'
import * as service from 'service'
import schemas from '../../schemas'
import useStyles from './styles'

const Form = ({
  whiteColor = false,
  email = '',
  enableRedirect = true,
  setClose,
}) => {
  const [loading, setLoading] = useState(false)
  const [recaptcha, setRecaptcha] = useState(undefined)

  const recaptchaRef = useRef(null)

  const production = process.env.REACT_APP_API === 'production'
  const disableSubmitButton = production ? !recaptcha || loading : false

  const history = useHistory()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schemas.forgotPassword,
    defaultValues: {
      email: email,
      password: '',
    },
  })

  const onSubmit = async (data) => {
    if (disableSubmitButton) return

    setLoading(true)

    try {
      const response = await service.dponet.auth.forgotPassword({
        ...data,
        recaptcha,
      })

      snackbar.open({
        message: response.data.message,
        variant: 'success',
      })

      if (enableRedirect) return history.push(routes.root)

      setClose()
    } catch (error) {
      if (production) resetRecaptcha()
      snackbar.open({
        message: defineErrorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const resetRecaptcha = () => {
    recaptchaRef.current.reset()
    setRecaptcha(undefined)
  }

  const defineErrorMessage = (error) => {
    const response = error?.response
    const status = response?.status
    const errorMessage = response.data?.error?.message

    if ((status === 404 || status === 422) && errorMessage) return errorMessage

    return 'Ocorreu algum erro! Tente novamente!'
  }

  const handleAcceptedRecaptcha = () => setRecaptcha(true)
  const disableRecaptcha = () => setRecaptcha(undefined)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={
          <TextField
            label={!whiteColor && 'E-mail'}
            color="primary"
            variant="outlined"
            error={!!errors.email}
            helperText={errors?.email?.message}
            className={clsx({
              [classes.inputWhite]: whiteColor,
            })}
            fullWidth
          />
        }
        control={control}
        name="email"
        mode="onChange"
      />
      {production && (
        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
          <ReCAPTCHA
            ref={recaptchaRef}
            render="explicit"
            sitekey={getGoogleRecaptchaToken()}
            onChange={handleAcceptedRecaptcha}
            onExpired={disableRecaptcha}
          />
        </Box>
      )}
      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Button
          type="submit"
          variant="contained"
          disabled={disableSubmitButton}
          className={clsx({
            [classes.buttonPrimaryWhite]: whiteColor,
            [classes.buttonPrimaryBlue]: !whiteColor,
          })}
        >
          {loading ? 'Enviando...' : 'Recuperar'}
        </Button>
      </Box>
    </form>
  )
}

Form.propTypes = {
  whiteColor: PropTypes.bool,
  email: PropTypes.string,
  enableRedirect: PropTypes.bool,
  setClose: PropTypes.func,
}

export default Form
