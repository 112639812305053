import api from 'service/api'

const dponetAPI = api.create('cookies', false)

const destroy = async ({ bannerId, bannerUrlId }) =>
  await dponetAPI.delete(`/banners/${bannerId}/urls/${bannerUrlId}`)

export default {
  destroy,
}
