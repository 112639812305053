import React, { memo, useEffect } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'

import BannerHeadCard from '../BannerHeadCard'
import BannerUrlField from '../BannerUrlField'

import theme from 'theme'
import constants from 'constants/index'

const BannerConfigurationCard = ({ banner }) => {
  const { register, errors, control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'urls',
  })

  const addUrl = () => {
    append({ url: '', id: null })
  }

  return (
    <Paper
      variant="outlined"
      id={constants.cookies.banner.COOKIES_BANNER_SETTING_DRIVER_STEP_2}
    >
      <BannerHeadCard name="Configuração do Banner" stage="01" />
      <Divider />
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(3)}
      >
        <Box
          p={2}
          bgcolor={theme.palette.custom.light}
          borderRadius={1}
          textAlign="justify"
        >
          <Typography color="primary" variant="h6" gutterBottom>
            Informação
          </Typography>
          <Typography color="primary">
            Um banner de cookies é um aviso exibido nos sites para informar e
            obter o consentimento dos usuários antes de rastrear e armazenar
            seus dados de navegação.
          </Typography>
        </Box>
        {constants.cookies.banner.COOKIE_CREATION_CARD_ITEMS.map(
          (item, index) => (
            <TextField
              key={index}
              error={!!errors?.[item.controlName]}
              helperText={errors?.[item.controlName]?.message}
              label={item.label}
              type="text"
              color="primary"
              variant="outlined"
              fullWidth
              inputRef={register()}
              name={item.controlName}
            />
          ),
        )}
        {fields.map((field, index) => (
          <BannerUrlField
            key={field.id}
            field={field}
            index={index}
            register={register}
            errors={errors}
            remove={remove}
            banner={banner}
          />
        ))}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={10}>
            <Box display="flex" alignItems="center" gridGap={theme.spacing(2)}>
              <Box bgcolor={theme.palette.primary.light} padding={2}>
                <Typography variant="h5" gutterBottom>
                  Possui mais de uma URL para o mesmo site?
                </Typography>
                <Typography>
                  Adicione as URLs do seu site para que o banner seja exibido em
                  todas elas. Não esqueça de variações como "www" e sem "www".
                  Exemplo: "https://www.site.com" e "https://site.com".
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={addUrl}
            >
              Adicionar URL
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default memo(BannerConfigurationCard)
