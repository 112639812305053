import * as yup from 'yup'

export const schema = yup.object().shape({
  name: yup.string().min(4).max(150),
  urls: yup
    .array()
    .of(
      yup.object().shape({
        url: yup.string().min(4).max(256).url('URL inválida'),
      }),
    )
    .min(1, 'Pelo menos uma URL é necessária'),
})

export default schema
