import React, { memo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { ConfirmationDialog } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'

const BannerUrlField = ({ field, index, remove, banner }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const { register, errors } = useFormContext()
  const snackbar = useSnackbar()

  const handleOpenDialog = () => {
    if (!field.id) return remove(index)

    setOpenDialog(true)
  }

  const handleConfirmRemove = async () => {
    try {
      await service.cookies.bannerUrl.destroy({
        bannerId: banner?.id,
        bannerUrlId: field.id,
      })

      remove(index)
      snackbar.open({
        message: 'Url removida com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setOpenDialog(false)
    }
  }

  return (
    <>
      <input
        type="hidden"
        name={`urls[${index}].id`}
        defaultValue={field.id || ''}
        ref={register()}
      />
      <TextField
        key={field.id}
        name={`urls[${index}].url`}
        defaultValue={field.url}
        inputRef={register()}
        error={!!errors?.urls?.[index]?.url}
        helperText={errors?.urls?.[index]?.url?.message}
        fullWidth
        InputProps={{
          endAdornment:
            index !== 0 ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="remover url"
                  onClick={handleOpenDialog}
                  edge="end"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
      />
      <ConfirmationDialog
        open={openDialog}
        setOpen={setOpenDialog}
        text="Tem certeza que deseja remover esta URL?"
        textButton="Remover"
        actionAcceptButton={handleConfirmRemove}
      />
    </>
  )
}

BannerUrlField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  banner: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
}

export default memo(BannerUrlField)
