import React from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm, useFieldArray } from 'react-hook-form'

import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { BaseDialog } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useStyles from './styles'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import schema from './schema'
import theme from 'theme'

const NewBannerModal = ({
  handleCreateModal,
  loading,
  open,
  refresh,
  setLoading,
  setOpen,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: '',
      urls: [{ url: '' }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'urls',
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const banner = {
        ...data,
        status: constants.cookies.banner.INCOMPLETE_STATUS_ID,
      }

      await service.cookies.banner.create({ banner })

      snackbar.open({
        message: 'Banner adicionado com sucesso!',
        variant: 'success',
      })
      reset()
      refresh()
      handleCreateModal()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Novo Banner de Cookies"
      dialogSize="md"
      fullWidth
      closeButtonText="Cancelar"
      actionButtonText="Adicionar"
      formRef="create-cookie-form"
      loading={loading}
    >
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
        <Box bgcolor={theme.palette.primary.light} padding={2}>
          <Typography variant="h5" gutterBottom>
            Informação
          </Typography>
          <Typography>
            Um banner de cookies é um aviso exibido nos sites para informar e
            obter o consentimento dos usuários antes de rastrear e armazenar
            seus dados de navegação.
          </Typography>
        </Box>
        <form id="create-cookie-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Nome"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                    fullWidth
                  />
                }
                name="name"
                control={control}
              />
            </Grid>
            {fields.map((field, index) => (
              <Grid
                key={field.id}
                item
                xs={12}
                container
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        label={`URL ${index + 1}`}
                        type="text"
                        color="primary"
                        variant="outlined"
                        error={!!errors.urls?.[index]}
                        helperText={errors.urls?.[index]?.message}
                        fullWidth
                        InputProps={{
                          endAdornment:
                            fields.length > 1 ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="remover url"
                                  onClick={() => remove(index)}
                                  edge="end"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    }
                    name={`urls[${index}].url`}
                    control={control}
                    defaultValue={field.url}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                gridGap={theme.spacing(2)}
              >
                <Box bgcolor={theme.palette.primary.light} padding={2}>
                  <Typography variant="h5" gutterBottom>
                    Possui mais de uma URL para o mesmo site?
                  </Typography>
                  <Typography>
                    Adicione as URLs do seu site para que o banner seja exibido
                    em todas elas. Não esqueça de variações como "www" e sem
                    "www". Exemplo: "https://www.site.com" e "https://site.com".
                  </Typography>
                </Box>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  className={classes.addUrlButton}
                  onClick={() => append({ url: '' })}
                >
                  Adicionar URL
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </BaseDialog>
  )
}

NewBannerModal.propTypes = {
  handleCreateModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default NewBannerModal
