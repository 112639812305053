import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = (loading) =>
  makeStyles((theme) => ({
    iconAnimation: {
      animation: loading ? '$spin 1s linear infinite' : 'none',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    menuItemUrl: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  }))

styles.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export default styles
