import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonPrimaryBlue: {
    backgroundColor: theme.palette.subscription.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.subscription.primary.dark,
    },
  },
  buttonPrimaryWhite: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.subscription.primary.main,
    border: `1px solid ${theme.palette.subscription.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  inputWhite: {
    backgroundColor: theme.palette.common.white,
    border: 'none',
    borderRadius: 5,
    '& input': {
      color: theme.palette.subscription.primary.main,
      fontSize: theme.typography.pxToRem(19),
      padding: 10,
      fontWeight: 700,
    },
  },
}))

export default styles
