import React, { useEffect, useState, useMemo, memo } from 'react'
import { useFormContext, useForm, Controller } from 'react-hook-form'
import { Loader as LoaderIcon, Link2 as Link2Icon } from 'react-feather'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
} from '@material-ui/core'

import { AlertDialog, LoadingFeedback } from 'components'
import { DetailsReadingCard, ScannerCard } from './components'
import BannerHeadCard from '../BannerHeadCard'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import styles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const CookieValidationCard = memo(({ cookies, banner, refresh }) => {
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const snackbar = useSnackbar()
  const { watch } = useFormContext()

  const watchedUrls = watch('urls')
  const watchedUrlId = watch('scannerUrlId')

  const { control } = useForm({
    defaultValues: {
      scannerUrlId: watchedUrls?.[0]?.id || '',
    },
  })

  const { initialUrls, currentUrls } = useMemo(() => {
    const initial = banner?.urls?.map((obj) => obj.url) || []
    const current = Array.isArray(watchedUrls)
      ? watchedUrls.map((item) => item.url)
      : []
    return { initialUrls: initial, currentUrls: current }
  }, [banner, watchedUrls])

  // Comparar as URLs atuais com as URLs iniciais
  const urlsChanged =
    currentUrls.length !== initialUrls.length ||
    currentUrls.some((url, index) => url !== initialUrls[index])

  const disableScanner = urlsChanged

  const loadingScanner =
    loading ||
    banner?.scanner?.status ===
      constants.cookies.banner.INITIATED_SCANNER_STATUS_ID

  const useStyles = styles(loadingScanner)
  const classes = useStyles()

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)

  const handleScan = async () => {
    try {
      setLoading(true)
      await service.cookies.banner.scanner({
        bannerId: banner?.id,
        urlId: watchedUrlId,
      })
      snackbar.open({
        message:
          'Escaneamento iniciado com sucesso! Isso pode levar alguns segundos.',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (
      banner?.scanner?.status ===
      constants.cookies.banner.INITIATED_SCANNER_STATUS_ID
    )
      handleOpenDialog()
  }, [banner?.scanner?.status])

  return (
    <Paper variant="outlined">
      <LoadingFeedback open={loading} />
      <BannerHeadCard name="Validação do Cookie" stage="04" />
      <Divider />
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
      >
        <Box
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(2)}
          id={constants.cookies.banner.COOKIES_BANNER_SETTING_DRIVER_STEP_5}
        >
          <Box
            p={2}
            bgcolor={theme.palette.custom.light}
            borderRadius={1}
            textAlign="justify"
          >
            <Typography color="primary">
              Verifique o código do seu site clicando no botão "Escanear". Nosso
              sistema analisará automaticamente o código em busca de erros ou
              inconsistências. Aguarde o feedback que indicará qualquer problema
              ou confirmará que tudo está correto.
            </Typography>
          </Box>
          <Box>
            <Typography color="primary" variant="h6" gutterBottom>
              Detalhes da Leitura
            </Typography>
            <DetailsReadingCard scanner={banner?.scanner} />
          </Box>
          {banner?.scanner?.status ===
            constants.cookies.banner.INITIATED_SCANNER_STATUS_ID && (
            <Alert variant="outlined" severity="warning">
              O seu site está sendo escaneado, este processo pode levar até 2
              minutos. Por favor, aguarde alguns instantes e atualize a página
              se necessário. Obrigado pela compreensão!
            </Alert>
          )}
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={4} lg={3}>
              <Controller
                name="scannerUrlId"
                control={control}
                as={
                  <TextField
                    select
                    size="small"
                    label="Selecione a URL"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Link2Icon size={20} />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {watchedUrls?.map((url) => (
                      <MenuItem key={url.id} value={url.id}>
                        {url.url}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Tooltip
                title={
                  (disableScanner &&
                    'Salve as alterações de "Configuração do Banner" antes de prosseguir com o escaneamento.') ||
                  (loadingScanner &&
                    'Por favor, atualize a página para verificar se o escaneamento foi concluído.') ||
                  ''
                }
              >
                <Box>
                  <Button
                    onClick={handleScan}
                    variant="contained"
                    color="primary"
                    startIcon={
                      <LoaderIcon size={18} className={classes.iconAnimation} />
                    }
                    disabled={disableScanner || loadingScanner}
                    fullWidth
                  >
                    {loadingScanner ? 'Escaneando' : 'Escanear'}
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <ScannerCard cookies={cookies} />
        </Box>
      </Box>
      <AlertDialog
        open={openDialog}
        onClose={handleCloseDialog}
        variant="outlined"
        severity="warning"
        content="O seu site está sendo escaneado, este processo pode levar até 2
          minutos. Por favor, aguarde alguns instantes e atualize a página se
          necessário. Obrigado pela compreensão!"
      />
    </Paper>
  )
})

CookieValidationCard.propTypes = {
  banner: PropTypes.object,
  cookies: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func.isRequired,
}

export default CookieValidationCard
