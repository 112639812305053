import {
  AlertOctagon as AlertOctagonIcon,
  BarChart2 as BarChartIcon,
  Briefcase as BriefcaseIcon,
  Clipboard as ClipboardIcon,
  File as FileIcon,
  Home as HomeIcon,
  Layers as LayersIcon,
  Play as PlayIcon,
  Shield as ShieldIcon,
  Package as PackageIcon,
  ShoppingCart as ShoppingCartIcon,
  Archive as ArchiveIcon,
  Feather as FeatherIcon,
  ThumbsUp as ThumbsUpIcon,
} from 'react-feather'

import { SVG } from 'components'

import { routes } from 'Routes'

import * as service from 'service'
import constants from 'constants/index'

const permission = constants.permissions

const mountMenuItems = (
  countBadgeResponse,
  isSupplier = false,
  user,
  defaultTicketChannel = true,
) => {
  const suppliersStep = user?.userSteps?.find(
    (userStep) => userStep?.tag === constants.userSteps?.STEP_SUPPLIERS_TAG,
  )

  return [
    {
      somePermissions: [permission.MARKETPLACE.VIEW],
      items: [
        {
          main: true,
          title: 'Marketplace',
          icon: ShoppingCartIcon,
          href: routes.marketplace.all,
          items: [
            {
              title: 'Início',
              href: routes.marketplace.all,
            },
            {
              title: 'Produtos e Serviços',
              href: routes.marketplace.products,
            },
            {
              title: 'Meus pedidos',
              href: routes.marketplace.orders,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Home',
          icon: HomeIcon,
          href: routes.myLgpd.home,
        },
      ],
    },
    {
      somePermissions: permission.MY_LGPD.SHOW,
      items: [
        {
          main: true,
          title: 'Dashboard',
          icon: BarChartIcon,
          href: routes.myLgpd.dashboard,
          somePermissions: permission.MY_LGPD.SHOW,
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Treinamentos',
          icon: PlayIcon,
          href: '?redirectToMoodle=true',
          alwaysUnlocked: true,
          showBlocked: isSupplier,
          permission: permission.MOODLE_TRAININGS.VIEW,
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Processos',
          icon: LayersIcon,
          somePermissions: [
            permission.DATA_PROCESSES.LIST,
            permission.DATA_PROCESSES.LIST_AND_MANAGE_SUGGESTED,
            permission.DATA_PROCESSES.RESUME,
            permission.AUDITS.VIEW,
            permission.AUDITS.MANAGE,
          ],
          href: routes.dataProcess.all,
          items: [
            {
              title: 'Resumo',
              href: routes.dataProcess.resume,
              somePermissions: [
                permission.DATA_PROCESSES.LIST,
                permission.DATA_PROCESSES.RESUME,
              ],
            },
            {
              title: 'Todos os processos',
              href: routes.dataProcess.all,
              permission: permission.DATA_PROCESSES.LIST,
            },
            {
              title: 'Novos sugeridos',
              href: routes.dataProcess.suggested,
              countToBadge: countBadgeResponse,
              permission: permission.DATA_PROCESSES.LIST_AND_MANAGE_SUGGESTED,
            },
            {
              title: 'Alterações sugeridas',
              href: routes.dataProcess.suggestedChanges,
              permission: constants.permissions.DATA_PROCESSES.CREATE,
            },
            {
              title: 'Auditoria',
              href: routes.audits.all,
              somePermissions: [
                permission.AUDITS.VIEW,
                permission.AUDITS.MANAGE,
              ],
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Diagnóstico',
          icon: FileIcon,
          href: routes.questionnaire.all,
          unlockedToSupplier: true,
          items: [
            {
              title: 'Questionários',
              href: routes.questionnaire.all,
              unlockedToSupplier: true,
            },
            {
              title: 'Medidas de segurança',
              showBlocked: isSupplier,
              href: routes.questionControls.all,
              permission: constants.permissions.DIAGNOSTICS.LIST,
            },
            {
              title: 'Ameaças',
              href: routes.companyFragilities.all,
              somePermissions: [
                permission.COMPANY_FRAGILITIES.LIST_COMPANY_FRAGILITIES,
                permission.COMPANY_FRAGILITIES
                  .LIST_COMPANY_FRAGILITIES_BY_DEPARTMENT,
                permission.COMPANY_FRAGILITIES.MANAGE_COMPANY_FRAGILITIES,
                permission.COMPANY_FRAGILITIES
                  .MANAGE_COMPANY_FRAGILITIES_BY_DEPARTMENT,
              ],
            },
          ],
        },
      ],
    },
    {
      somePermissions: [permission.INCIDENTS.LIST],
      items: [
        {
          main: true,
          title: 'Incidentes',
          icon: AlertOctagonIcon,
          permission: permission.INCIDENTS.LIST,
          href: routes.incidents.all,
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Relatórios',
          icon: ClipboardIcon,
          href: routes.reports.all,
          somePermissions: [permission.RIPD.EXPORT],
          items: [
            {
              title: 'Relatório Gerencial',
              href: routes.reports.management,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Impacto',
              href: routes.reports.ripd,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'RoPA',
              href: routes.reports.ropa,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Prestação de contas',
              href: routes.reports.accountability,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Medidas de Segurança',
              href: routes.reports.control,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Ameaças',
              href: routes.reports.fragility,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Treinamento',
              href: routes.reports.training,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Legítimo Interesse',
              href: routes.reports.lia,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Consentimentos',
              href: routes.reports.consent,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Auditoria',
              href: routes.reports.audit,
              permission: permission.RIPD.EXPORT,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Canal de Comunicação',
          icon: ShieldIcon,
          alwaysUnlocked: true,
          href: routes.privacyStamp,
          somePermissions: [
            permission.PRIVACY_POLICIES.STAMP,
            permission.COMPLAINTS.LIST,
            permission.COMPLAINTS.REGISTER_RESPONSIBLE,
            permission.COMPLAINTS.SHOW,
            permission.COMPLAINT_MESSAGES.CREATE,
            permission.COMPLAINTS.LIST_CONFIGURATIONS,
            permission.COMPLAINTS.MANAGE_CONFIGURATIONS,
            permission.COMPLAINTS.ACTIVATE_COMPLAINTS,
          ],
          items: [
            {
              title: 'Solicitações de titulares',
              href: routes.titularsSolicitations,
              alwaysUnlocked: true,
              somePermissions: [permission.PRIVACY_POLICIES.STAMP],
            },
            {
              title: 'Meios de Contato',
              href: routes.privacyStamp,
              somePermissions: [permission.PRIVACY_POLICIES.STAMP],
              alwaysUnlocked: true,
            },
            {
              title: 'Canal de Denúncia',
              href: routes.complaint.all,
              alwaysUnlocked: true,
              somePermissions: [
                permission.COMPLAINTS.LIST,
                permission.COMPLAINTS.REGISTER_RESPONSIBLE,
                permission.COMPLAINTS.SHOW,
                permission.COMPLAINT_MESSAGES.CREATE,
                permission.COMPLAINTS.ACTIVATE_COMPLAINTS,
                permission.COMPLAINTS.MANAGE_CONFIGURATIONS,
                permission.COMPLAINTS.LIST_CONFIGURATIONS,
              ],
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          icon: PackageIcon,
          title: 'Gestão de Terceiros',
          unlockedToSupplier: true,
          href: routes.thirdPartyManagements.intermediator,
          somePermissions: [
            permission.COMPANY_SUPPLIERS.SHOW,
            permission.COMPANY_SUPPLIERS.UPDATE,
          ],
          ...(isSupplier && {
            items: [
              {
                title: 'Parceiros',
                permission: permission.COMPANY_SUPPLIERS.LIST_IAM_SUPPLIER,
                href: routes.thirdPartyManagements.partners.all,
              },
              {
                title: 'Convites',
                permission: permission.COMPANY_SUPPLIERS.LIST_IAM_SUPPLIER,
                href: routes.thirdPartyManagements.invites,
              },
              {
                title: 'Pré-Avaliações',
                permission: permission.COMPANY_SUPPLIERS.LIST_IAM_SUPPLIER,
                href: routes.thirdPartyManagements.preAvaliations,
              },
            ],
          }),
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Consentimentos',
          icon: ThumbsUpIcon,
          alwaysUnlocked: true,
          somePermissions: [permission.CONSENTS.LIST],
          href: routes.consent.all,
          items: [
            {
              title: 'Formulários',
              href: routes.consent.all,
              permission: permission.CONSENTS.LIST,
            },
            {
              title: 'Recibos',
              href: routes.consent.consentReceipt,
              permission: permission.CONSENT_RECEIPTS.LIST,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Empresa',
          icon: BriefcaseIcon,
          alwaysUnlocked: true,
          somePermissions: [
            permission.DEPARTMENTS.LIST,
            permission.USERS.LIST,
            permission.PROFILES.LIST,
          ],
          items: [
            {
              title: 'Departamentos',
              href: routes.department.all,
              permission: permission.DEPARTMENTS.LIST,
            },
            {
              title: 'Usuários',
              href: routes.user.all,
              permission: permission.USERS.LIST,
              alwaysUnlocked: true,
            },
            {
              title: 'Perfis',
              permission: constants.permissions.PROFILES.LIST,
              href: routes.profiles.all,
              alwaysUnlocked: true,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Documentos',
          icon: ArchiveIcon,
          alwaysUnlocked: true,
          href: routes.documents.all,
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Cookies',
          show: service.cookies.auth.getToken() ? true : false, // necessário implementar permissões na api de cookies
          icon: SVG.CookieIcon,
          alwaysUnlocked: true,
          href: routes.cookies.banners,
          items: [
            {
              title: 'Meus banners',
              href: routes.cookies.banners,
            },
            {
              title: 'Consentimentos',
              href: routes.cookies.consents,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: 'Central da Privacidade',
          icon: FeatherIcon,
          alwaysUnlocked: true,
          href: routes.technicalFaqs.lgpdMain,
          items: [
            {
              title: 'LGPD',
              href: routes.technicalFaqs.lgpdMain,
              alwaysUnlocked: true,
            },
            {
              title: 'Segurança da Informação',
              href: routes.technicalFaqs.informationSecurityMain,
              alwaysUnlocked: true,
            },
            {
              title: 'Solicitações',
              href: routes.technicalFaqs.tickets,
              alwaysUnlocked: true,
              show: defaultTicketChannel,
            },
          ],
        },
      ],
    },
  ]
}

export default mountMenuItems
