import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { AppBar, Toolbar, Box } from '@material-ui/core'

import useCustomTheme from 'hooks/useCustomTheme'

import { Account } from './components'

import useStyles from './styles'

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()
  const { customLogo, isCompany } = useCustomTheme()

  return (
    <AppBar elevation={3} className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        {isCompany && (
          <Box mb={1}>
            <img
              alt="Logo"
              src={customLogo}
              className={classes.logo}
              width="100%"
            />
          </Box>
        )}
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
