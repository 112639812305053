import React from 'react'
import { Box, lighten, Paper, Typography } from '@material-ui/core'

import { BarChart } from 'components'

import useStyles from './styles'
import theme from 'theme'

const QuestionnairesProgress = ({ questionnaires = [] }) => {
  const classes = useStyles()

  const kinds = questionnaires.map((questionnaire) => questionnaire.kind)
  const series = questionnaires.map((questionnaire) =>
    Math.round(questionnaire.answerPercentage),
  )

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Perguntas respondidas por questionário (%)
      </Typography>

      <Paper className={classes.paper}>
        <BarChart
          colors={[theme.palette.primary.main]}
          series={[{ name: 'Respondidas (%)', data: series }]}
          names={kinds}
          heightValue={205}
          borderRadius={7}
          enabledTotal
          categoryFontSize={10}
          gradient
          gradientColor={lighten(theme.palette.azure, 0.5)}
          max={100}
        />
      </Paper>
    </Box>
  )
}

export default QuestionnairesProgress
