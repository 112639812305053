import React, { useState, useEffect } from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Card, LoadingFeedback } from 'components'
import { OptionalForm, RequiredForm } from './components'
import QuestionnaireForm from '../QuestionnaireForm'

import useSnackbar from 'hooks/useSnackbar'

import requiredFormSchema from './components/RequiredForm/schema'
import optionalFormSchema from './components/OptionalForm/schema'
import theme from 'theme'
import * as service from 'service'
import helpers from 'helpers'
import { routes } from 'Routes'
import constants from 'constants/index'
import NoticeDialog from './components/NoticeDialog'
import { isEmpty } from 'lodash'

const RegisterForm = ({
  registerType,
  isEdit = false,
  supplierInvite = {},
  supplierFile = null,
  questionnaires = [],
}) => {
  const [selectedQuestionnaires, setSelectedQuestionnaires] =
    useState(questionnaires)
  const [loading, setLoading] = useState(false)
  const [openNoticeDialog, setOpenNoticeDialog] = useState(false)
  const [documentChanged, setDocumentChanged] = useState(false)
  const [responsibleEmail, setResponsibleEmail] = useState('')

  const snackbar = useSnackbar()
  const history = useHistory()

  const editionPermitted =
    !isEdit ||
    supplierInvite?.companySupplierDetail?.companySupplier?.editionPermitted

  const requiredForm = useForm({
    validationSchema: requiredFormSchema,
    defaultValues:
      helpers.thirdPartyManegementPartners.mountDefaultValues.registerForm(
        supplierInvite,
        isEdit,
      ),
  })

  const optionalForm = useForm({
    validationSchema: optionalFormSchema,
    defaultValues:
      helpers.thirdPartyManegementPartners.mountDefaultValues.optionalForm(
        supplierInvite,
        supplierFile,
      ),
  })

  const handleReturn = () => {
    history.goBack()
  }

  const onSubmit = async () => {
    const requiredFormDataValid = await requiredForm.triggerValidation()
    const optionalFormDataValid = await optionalForm.triggerValidation()

    if ((!requiredFormDataValid && editionPermitted) || !optionalFormDataValid)
      return

    setLoading(true)

    const requiredFormData = requiredForm.getValues()
    const optionalFormData = optionalForm.getValues()

    try {
      if (isEdit) {
        await service.dponet.suppliers.put({
          supplierInviteId: supplierInvite?.id,
          ...helpers.thirdPartyManegementPartners.mountBodyParams.update(
            requiredFormData,
            optionalFormData,
            selectedQuestionnaires,
            documentChanged,
          ),
        })

        redirect()
      } else {
        const response = await service.dponet.suppliers.create(
          helpers.thirdPartyManegementPartners.mountBodyParams.create(
            registerType,
            requiredFormData,
            optionalFormData,
            selectedQuestionnaires,
          ),
        )

        const supplier = response?.data?.supplier

        if (supplier?.existsPreviously && supplier?.responsibleUserEmail) {
          setOpenNoticeDialog(true)
          setResponsibleEmail(supplier?.responsibleUserEmail)
        } else {
          redirect()
        }
      }

      snackbar.open({
        message: `${isEdit ? 'Editado' : 'Criado'} com sucesso.`,
        variant: 'success',
      })
    } catch (error) {
      const default_message = `Ocorreu um erro ao ${
        isEdit ? 'editar' : 'criar'
      } um novo parceiro.`

      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || default_message,
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const redirect = () => {
    const preAvaliationRegisterType =
      constants.thirdPartyManagements.REGISTER_TYPES.PRE_AVALIATION
    const acceptedSupplierInviteStatus =
      constants.supplierInvite.STATUSES.ACCEPTED

    if (registerType === preAvaliationRegisterType) {
      return history.push(routes.thirdPartyManagements.preAvaliations)
    }

    if (isEdit && supplierInvite?.status === acceptedSupplierInviteStatus) {
      return history.push(routes.thirdPartyManagements.partners.all)
    }

    history.push(routes.thirdPartyManagements.invites)
  }

  const handleCloseNoticeDialog = () => {
    setOpenNoticeDialog(false)
    redirect()
  }

  useEffect(() => {
    const document = optionalForm.watch('document')

    if (!documentChanged && !!document && document !== supplierFile) {
      setDocumentChanged(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionalForm.watch('document')])


  return (
    <Grid container spacing={3}>
      <LoadingFeedback open={loading} />
      <Grid item xs={12}>
        <Card
          id={constants.tours.thirdPartyManagements.STEPS.CREATION.DEFAULT[2]}
          title="Informações Obrigatórias"
        >
          <FormContext {...requiredForm}>
            <RequiredForm isEdit={isEdit} editionPermitted={editionPermitted} />
          </FormContext>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="Informações Opcionais">
          <FormContext {...optionalForm}>
            <OptionalForm isEdit={isEdit} />
          </FormContext>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card
          id={constants.tours.thirdPartyManagements.STEPS.CREATION.DEFAULT[7]}
          title="Questionários"
        >
          <QuestionnaireForm
            selectedQuestionnaires={selectedQuestionnaires}
            setSelectedQuestionnaires={setSelectedQuestionnaires}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="flex-end"
          gridGap={theme.spacing(2)}
        >
          <Button variant="outlined" onClick={handleReturn}>
            Voltar
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Salvar
          </Button>
        </Box>
      </Grid>

      <NoticeDialog
        open={openNoticeDialog}
        onClose={handleCloseNoticeDialog}
        responsibleEmail={responsibleEmail}
      />
    </Grid>
  )
}

export default RegisterForm
