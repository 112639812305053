import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Eye } from 'react-feather'
import { reverse } from 'named-urls'
import { Box, Typography, IconButton } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { LoadingFeedback } from 'components'

import { routes } from 'Routes'

import helpers from 'helpers'

const TableRowTicket = ({ ticket, refresh, handleChange, ...rest }) => {
  const history = useHistory()

  const navigateToEdit = (ticketId) => {
    history.push(
      reverse(routes.technicalFaqs.ticketDetails, {
        ticketId: ticketId,
      }),
    )
  }

  return (
    <TableRow {...rest}>
      <TableCell>{ticket?.id}</TableCell>
      <TableCell>
        <Typography>{ticket?.title}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{ticket?.user?.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography align="center">
          {helpers.formatters.date(ticket?.createdAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography align="right">
          {helpers.formatters.date(ticket?.updatedAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography align="right">{ticket?.status?.name}</Typography>
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={() => navigateToEdit(ticket?.id)}>
          <Eye width="18" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const TicketTable = ({ ticketData, isLoading, refresh }) => {
  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Box width="100%">
        <PerfectScrollbar
          options={{
            useBothWheelAxes: true,
            suppressScrollX: false,
          }}
        >
          <Table
            size="medium"
            emptyMessage="Nenhuma solicitação encontrada"
            isLoading={isLoading}
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="10%">Identificador</TableCell>
                <TableCell width="20%">Categoria</TableCell>
                <TableCell width="20%">Solicitante</TableCell>
                <TableCell width="15%" align="center">
                  Data de Criação
                </TableCell>
                <TableCell width="15%" align="right">
                  Ultima interação
                </TableCell>
                <TableCell width="10%" align="right">
                  Status
                </TableCell>
                <TableCell width="10%" align="right">
                  Visualizar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketData?.tickets.map((ticket) => (
                <TableRowTicket
                  ticket={ticket}
                  key={ticket?.id}
                  refresh={refresh}
                  hover
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </>
  )
}

TicketTable.propTypes = {
  ticketData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  refresh: PropTypes.func,
}

TableRowTicket.propTypes = {
  ticket: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  refresh: PropTypes.func,
}

TicketTable.defaultProps = {
  refresh: () => {},
  isLoading: false,
}

TableRowTicket.defaultProps = {
  handleChange: () => {},
  refresh: () => {},
}

export default TicketTable
