import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    border: 0,
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.primary.main,
    height: '60px',
  },
  logo: {
    fontSize: 0,
    width: '60px',
    [theme.breakpoints.up('md')]: {
      width: '100px',
    },
  },
  toolbar: {
    minHeight: 68,
  },
}))

export default styles
